import React from "react";
import cover from "../../../Assets/images/cover2.png"

const Header = ({ homeContent }) => {

  return (
    <div>
      <img src={cover} width="100%" />
    </div>
  );
};

export default Header;
